import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import Info from "../shared/about/Info"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    room: file(relativePath: { eq: "noone/building.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
  }
`

const AboutInfo = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "noone（ノーネ）",
    ceo: "中村　佑也",
    openDate: "2019年5月1日",
    address: "〒360-0162\n埼玉県熊谷市村岡469番地（コンビニ裏）",
    openingHours:
      "通常 (火〜土) ／ 9:30 - 18:30\n長期休み (春・夏・冬休みなど)／​8:30 - 17:30",
    phone: "048-598-4023",
    target: "noone" as Target,
    image: getImage(data.room),
    mapLink: "https://g.page/noone-jp?share",
  }
  return (
    <div>
      <HeadingM title="施設について" target="noone" />
      <Info {...items} />
    </div>
  )
}

export default AboutInfo
