import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import FooterBase from "../shared/Footer"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "noone/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "noone（ノーネ）",
    subName: "児童発達支援・放課後等デイサービス",
    target: "noone" as Target,
    appNum: 1153100365,
    address: "〒360-0162\n埼玉県熊谷市村岡469番地（コンビニ裏）",
    tel: "048-598-4023",
    fax: "048-598-4026",
    logo: getImage(data.logo),
  }

  return <FooterBase {...items} />
}

export default Footer
